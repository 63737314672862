.Intro {
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  margin-left: calc(8px + 2vw);
  margin-right: calc(8px + 16vw);
}

.Intro-header {
  font-size: calc(8px + 4vh);
}

.Intro-desc {
  font-weight: bolder;
  font-size: calc(8px + 2vh);
  margin: 0px;
  padding: 0px;
}

@media screen and (max-width: 1132px) {
  .Intro {
    flex-direction: column;
    justify-content: center;
    margin-right: calc(8px + 2vw);
  }
}

.Arrow {
  font-size: 20vmin;
  animation: Intro-arrow-anim 5s ease infinite;
}

@keyframes Intro-arrow-anim {
  0% {
    opacity: 1;
    transform: translateY(16px);
  }

  25% {
    opacity: 0.4;
    transform: translateY(-16px);
  }

  50% {
    opacity: 1;
    transform: translateY(16px);
  }

  75% {
    opacity: 0.4;
    transform: translateY(-16px);
  }

  100% {
    opacity: 1;
    transform: translateY(16px);
  }
}
