.Skills {
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  margin-left: calc(8px + 2vw);
  margin-right: calc(8px + 16vw);
}

.Skills-header {
  text-align: start;
  font-size: calc(10px + 5vmin);
  padding-left: 0.75vw;
  padding-right: 0.75vw;
}

.Skills-desc {
  text-align: start;
  font-weight: bolder;
  font-size: calc(8px + 1.5vmin);
  margin: 0px;
  padding: 0px;
}

.Skills-detail {
  text-align: start;
  font-weight: normal;
  font-size: calc(8px + 1vmin);
  margin: 0px;
  padding: 0px;
}

@media screen and (max-width: 1132px) {
  .Skills {
    flex-direction: column-reverse;
    justify-content: center;
    margin-right: calc(8px + 2vw);
  }
  .Skills-header {
    text-align: center;
  }
}

.Star {
  font-size: 20vmin;
}

.Link {
  text-decoration: underline white;
  color: white;
}
