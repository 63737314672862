@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Bold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff')
    format('woff');
  font-weight: 700;
  font-style: normal;
}

#root {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  letter-spacing: 0.05em;
  overflow: hidden;
  word-break: keep-all;
}

body {
  background: #1F1F1F;
  font-family: 'Pretendard-Regular';
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.font-bold {
  font-family: 'Pretendard-Bold';
  font-weight: lighter;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #1F1F1F;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.highlight-gold {
  background: linear-gradient(to top, #FCC447 100%, transparent 100%);
}

.highlight-ocean {
  background: linear-gradient(to top, #2E999C 100%, transparent 100%);
}

.highlight-white {
  background: linear-gradient(to top, white 100%, transparent 100%);
}

.highlight-red {
  background: linear-gradient(to top, #dc143c 100%, transparent 100%);
}

.highlight-lapis {
  background: linear-gradient(to top, #5977d3 100%, transparent 100%);
}

.text-white {
  color: white;
}

.text-black {
  color: #1F1F1F;
}

.swiper-container-cube {
  overflow: visible;
  .swiper-slide {
    pointer-events: none;
    backface-visibility: hidden;
    z-index: 1;
    visibility: hidden;
    transform-origin: 0 0;
    width: 100%;
    height: 100%;
    .swiper-slide {
      pointer-events: none;
    }
  }
  &.swiper-container-rtl .swiper-slide {
    transform-origin: 100% 0;
  }
  .swiper-slide-active {
    &,
    & .swiper-slide-active {
      pointer-events: auto;
    }
  }
  .swiper-slide-active,
  .swiper-slide-next,
  .swiper-slide-prev,
  .swiper-slide-next + .swiper-slide {
    pointer-events: auto;
    visibility: visible;
  }
  .swiper-slide-shadow-top,
  .swiper-slide-shadow-bottom,
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    z-index: 0;
    backface-visibility: hidden;
  }
  .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    z-index: 0;

    &:before {
      content: '';
      background: #000;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      -webkit-filter: blur(50px);
      filter: blur(50px);
    }
  }
}
